.buttonsWrapper {
  @apply mx-6 flex overflow-x-scroll md:w-full lg:mx-10 2xl:justify-center;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.item {
  @apply flex shrink-0 items-center justify-center rounded-lg px-4 py-1 text-xs uppercase leading-tight text-mpGrey transition-all focus:outline-none md:px-6 md:py-2;
  &_selected {
    @extend .item;
    @apply bg-[rgb(0,0,0)]/[0.04] text-black;
  }
}
