@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryYellow: #fed900;
  --white: #ffffff;
  --black: #000000;
  --mpGrey: #908e8d;
  --lightGrey: #efeeee;
  --grey: #d8d8d8;
}

body {
  margin: 0;
  font-family:
    'Trebuchet MS',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
